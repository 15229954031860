<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>Jadwal Pelajaran</v-toolbar-title>
    </template>
    <v-container>
      <img-cover :src="require('@/assets/img/books.jpg')"></img-cover>

      <div class="text-h6 ml-4 mb-2 mt-6">
        <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
        {{ $store.state.pageTitle }}
      </div>
      <v-divider class="mb-4"></v-divider>

      <v-skeleton-loader
        class="mx-auto"
        type="list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar"
        v-if="loading"
      ></v-skeleton-loader>

      <v-expansion-panels v-model="panel" accordion multiple v-if="myData.length > 0">
        <v-expansion-panel v-for="(d, x) in myData" :key="x" class="border-0">
          <v-expansion-panel-header
            >Kelas {{ d.classroom.grade }}
            {{ d.classroom.subgrade }}</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div v-for="(s, i) in d.classroom.schedule" :key="i">
              <div class="text-h5 ml-4 mb-2" style="width: 100%">
                <v-icon color="blue">mdi-calendar-multiple-check</v-icon>
                {{ s.name }}
              </div>
              <v-divider></v-divider>
              <div class="py-4">
                <v-list denase height="100%" width="100%" class="mx-0">
                  <v-list-item-group color="primary">
                    <v-list-item v-for="(sd, ii) in s.data" :key="ii" two-line>
                      <v-list-item-avatar class="d-flex align-start">
                        <v-avatar color="blue" size="36">
                          <span class="white--text text-h6">{{
                            sd.avatar_text
                          }}</span>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="sd.subjects_name"
                        ></v-list-item-title>

                        <v-row>
                          <v-col>
                            <v-list-item-subtitle
                              v-text="sd.teacher_fullname"
                            ></v-list-item-subtitle>
                          </v-col>
                          <v-col class="text-right">
                            <p class="text-body-2 my-0">
                              {{ sd.time_start }} - {{ sd.time_end }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-alert color="warning" v-if="(loading == false) && (myData.length == 0)"
        >Oppss.. Belum ada data.</v-alert
      >
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ImgCover from "@/components/ImgCover.vue";

export default {
  name: "SubjectsSchedule",
  components: { DefaultLayout, ImgCover },

  data: () => ({
    myData: [],
    panel: [0],
    loading: null,
  }),

  methods: {
    async getData() {
      this.loading = true;
      await this.$axios.get("subjects/schedule").then((res) => {
        this.loading = false;
        if (res.data.status == "success") {
          if(typeof res.data.data[0][0] !== 'undefined')
          this.myData = res.data.data;

          // console.log(res.data);
        } else {
          this.myData = [];
        }
      });
    },
  },

  mounted() {
    this.getData();
    this.setPageTitle("Jadwal Pelajaran", "mdi-book");
  },
};
</script>